import React from "react";
import { colors } from "../CONSTANTS";

const CoinsIcon = ({ color = colors.white }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="coin" transform="translate(-684 -444)">
        <path
          id="Vector"
          d="M2.83,6.6H1.33A2.368,2.368,0,0,1-1,4.2a1,1,0,0,1,1-1,1,1,0,0,1,.227.026A2.027,2.027,0,0,1-1,1.22,2.193,2.193,0,0,1,1.16-1H2.674A2.389,2.389,0,0,1,5,1.4a1,1,0,0,1-1.231.973A2.031,2.031,0,0,1,4.99,4.38,2.193,2.193,0,0,1,2.83,6.6ZM.5,3.336A1,1,0,0,1,1,4.2a.38.38,0,0,0,.33.4h1.5c.076,0,.16-.094.16-.22a.839.839,0,0,0-.014-.17.926.926,0,0,0-.112-.048Zm.51-1.948a.971.971,0,0,0,.121.05L3.5,2.264A1,1,0,0,1,3,1.4.383.383,0,0,0,2.651,1H1.16C1.084,1,1,1.094,1,1.22A.849.849,0,0,0,1.013,1.387Z"
          transform="translate(692 451.2)"
          fill={color}
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,1.74a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V.74A1,1,0,0,1,0,1.74Z"
          transform="translate(694 456.85)"
          fill={color}
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,1.78a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V.78A1,1,0,0,1,0,1.78Z"
          transform="translate(694 450.41)"
          fill={color}
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M7.99-1A8.99,8.99,0,1,1-1,7.99,9,9,0,0,1,7.99-1Zm0,15.98A6.99,6.99,0,1,0,1,7.99,7,7,0,0,0,7.99,14.98Z"
          transform="translate(686 446)"
          fill={color}
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M4.05,9.98A5.976,5.976,0,0,1-.816,7.458,1,1,0,0,1-.578,6.064,1,1,0,0,1,.816,6.3,3.952,3.952,0,0,0,8,4.03,3.978,3.978,0,0,0,6.346.812a1,1,0,0,1-.227-1.4,1,1,0,0,1,1.4-.227A5.953,5.953,0,0,1,4.05,9.98Z"
          transform="translate(696.98 457)"
          fill={color}
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          transform="translate(684 444)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};

export default CoinsIcon;
