import React from "react";
import { colors } from "../CONSTANTS";

const ChatBubble = ({
  text = "",
  color = colors.black,
  textColor = colors.white,
  received = true,
}) => {
  return (
    <div
      className="selector"
      style={{
        display: "inline-flex",
        flexDirection: "column",
        verticalAlign: "top",
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 10,
        paddingLeft: 10,
        borderRadius: 10,
        borderTopLeftRadius: received ? 1 : 10,
        borderTopRightRadius: !received ? 1 : 10,
        backgroundColor: color,
        color: textColor,
      }}
    >
      {text}
    </div>
  );
};

export default ChatBubble;
