import "./App.css";
import UserTagComponent from "./components/UserTagComponent";
import TagComponent from "./components/TagComponent";
import ClubIcon from "./icons/ClubIcon";
import BurgerPin from "./components/PinComponents/BurgerPin";
import IconComponent from "./components/IconComponent";
import RoomCard from "./components/RoomCard";
import EventCard from "./components/EventCard";
import OpeningHourCard from "./components/OpeningHourCard";
import NotificationComponent from "./components/NotificationComponent";
import ChatBubble from "./components/ChatBubble";
import { colors } from "./CONSTANTS";
import UsersTagComponent from "./components/UsersTagComponent";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import ImprintPage from "./pages/ImprintPage";
import React from "react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import FeaturesPage from "./pages/FeaturesPage";
import ForLocationsPage from "./pages/ForLocationsPage";

function App() {
  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* <UserTagComponent username="Pete" />
      <TagComponent content="#Friends" />
      <ClubIcon />
      <BurgerPin />
      <IconComponent icon={<ClubIcon />} />
      <RoomCard />
      <EventCard />
      <OpeningHourCard />
      <NotificationComponent />
      <ChatBubble text="Will there be a crazy event happening?" />
      <ChatBubble text="Bro let's go out this weekend" received={false} color={colors.blue} />
      <UsersTagComponent
        text="8 friends"
      /> */}

      <BrowserRouter>
        <NavBar />
        <div style={{ flex: 1, boxSizing: "border-box" }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/imprint" element={<ImprintPage />} />
            <Route path="/for-locations" element={<ForLocationsPage />} />
          </Routes>
        </div>
        <div
          style={{
            paddingTop: 40,
            flexShrink: 0,
            textAlign: "center",
          }}
        >
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
