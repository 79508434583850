import React from "react";
import basketballs from "../assets/basketballs.png";

const RoomCard = () => {
  return (
    <div
      className="selector"
      style={{
        display: "inline-flex",
        flexDirection: "column",
        verticalAlign: "top",
        backgroundColor: "rgba(255, 255, 255, 0)",
        backdropFilter: "blur(30px) brightness(60%)",
        borderRadius: 20,
        overflow: "hidden",
      }}
    >
      <img
        src={basketballs}
        className="img"
        style={{
          width: 157,
          height: 94,
          display: "block",
        }}
      />
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          padding: 10,
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: "black",
          }}
        >
          Basketball
        </div>
        <div style={{ fontSize: 14, color: "white" }}>Best sport ever!</div>
        <div
          style={{
            marginTop: 10,
            color: "black",
            height: 34,
            borderRadius: 12,
            backgroundColor: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: 16, fontWeight: "600", color: "white" }}>
            Join
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
