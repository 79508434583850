import React from "react";
import { colors } from "../CONSTANTS";

const ClubIcon = ({ color = colors.purple }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="ClubIcon" transform="translate(-433 -2129)">
        <path
          id="Rectangle_4312"
          data-name="Rectangle 4312"
          d="M2,2V22H22V2H2M0,0H24V24H0Z"
          transform="translate(433 2129)"
          fill={color}
          opacity="0"
        />
        <g id="icons8-disco-ball" transform="translate(434 2130)">
          <g id="Group_2816" data-name="Group 2816">
            <path
              id="Ellipse_373"
              data-name="Ellipse 373"
              d="M7.619-1A8.619,8.619,0,1,1-1,7.619,8.629,8.629,0,0,1,7.619-1Zm0,15.238A6.619,6.619,0,1,0,1,7.619,6.627,6.627,0,0,0,7.619,14.238Z"
              transform="translate(3.381 3.857)"
              fill={color}
            />
            <path
              id="Path_3644"
              data-name="Path 3644"
              d="M20.372,25.929A11.7,11.7,0,0,1,17,17.609c0-3.915,1.182-6.748,3.512-8.421l1.166,1.625C19.9,12.088,19,14.375,19,17.609a9.638,9.638,0,0,0,2.819,6.938Z"
              transform="translate(-10.095 -5.905)"
              fill={color}
            />
            <path
              id="Path_3645"
              data-name="Path 3645"
              d="M24.723,25.929l-1.447-1.381A9.639,9.639,0,0,0,26.1,17.609c0-3.234-.9-5.521-2.678-6.8l1.166-1.625c2.33,1.673,3.512,4.506,3.512,8.421A11.7,11.7,0,0,1,24.723,25.929Z"
              transform="translate(-13.238 -5.905)"
              fill={color}
            />
            <path
              id="Line_79"
              data-name="Line 79"
              d="M15.238,1H0V-1H15.238Z"
              transform="translate(3.619 11.476)"
              fill={color}
            />
            <path
              id="Line_80"
              data-name="Line 80"
              d="M13.333,1H0V-1H13.333Z"
              transform="translate(4.095 7.667)"
              fill={color}
            />
            <path
              id="Line_81"
              data-name="Line 81"
              d="M13.333,1H0V-1H13.333Z"
              transform="translate(4.095 15.286)"
              fill={color}
            />
            <path
              id="Line_82"
              data-name="Line 82"
              d="M0,3.857a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V2.857A1,1,0,0,1,0,3.857Z"
              transform="translate(11 1)"
              fill={color}
            />
            <path
              id="Line_83"
              data-name="Line 83"
              d="M0,4.81a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V3.81A1,1,0,0,1,0,4.81Z"
              transform="translate(19.095 1)"
              fill={color}
            />
            <path
              id="Line_84"
              data-name="Line 84"
              d="M3.81,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H3.81a1,1,0,0,1,1,1A1,1,0,0,1,3.81,1Z"
              transform="translate(17.19 2.905)"
              fill={color}
            />
            <path
              id="Line_85"
              data-name="Line 85"
              d="M0,3.857a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V2.857A1,1,0,0,1,0,3.857Z"
              transform="translate(2.429 17.667)"
              fill={color}
            />
            <path
              id="Line_86"
              data-name="Line 86"
              d="M2.857,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H2.857a1,1,0,0,1,1,1A1,1,0,0,1,2.857,1Z"
              transform="translate(1 19.095)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ClubIcon;
