import React from "react";
import "./Imprint.css";
import "./Home.css";
import "./Features.css";
import "./ItemPositions.css";

const ForLocationsPage = () => {
  return (
    <div class="sidePadding fontLargeTitle" style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}>
      Coming soon
    </div>
  );
};

export default ForLocationsPage;
