import React from "react";
import { colors } from "../CONSTANTS";

const CloseIcon = ({ color = colors.black }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="CloseIcon" transform="translate(-14101 -23867)">
        <g id="Group_1412" data-name="Group 1412">
          <path
            id="Vector"
            d="M12,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H12a1,1,0,0,1,1,1A1,1,0,0,1,12,1Z"
            transform="translate(14108.758 23874.758) rotate(45)"
            fill={color}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,13a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V12A1,1,0,0,1,0,13Z"
            transform="translate(14117.242 23874.758) rotate(45)"
            fill={color}
          />
        </g>
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          transform="translate(14101 23867)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
