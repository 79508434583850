import React from "react";
import golfEvent from "../assets/golf.png";
import CalendarIcon from "../icons/CalendarIcon";
import { colors } from "../CONSTANTS";
import CoinsIcon from "../icons/CoinsIcon";

const EventCard = () => {
  return (
    <div
      className="selector"
      style={{
        display: "inline-flex",
        flexDirection: "column",
        verticalAlign: "top",
        backgroundColor: "rgba(255, 255, 255, 0)",
        backdropFilter: "blur(30px) brightness(60%)",
        borderRadius: 20,
        overflow: "hidden",
      }}
    >
      <div
        className="titleGradient"
        style={{
          position: "absolute",
          width: 157,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: "600",
            paddingTop: 10,
            paddingBottom: 10,
            color: colors.white,
            textAlign: "center",
          }}
        >
          Golf Event
        </div>
      </div>
      <img
        src={golfEvent}
        className="img"
        style={{
          width: 157,
          height: 94,
          display: "block",
        }}
      />
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          padding: 10,
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarIcon />
          <div style={{ fontSize: 16, color: colors.green, marginLeft: 5 }}>
            Right now
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <CoinsIcon />
          <div style={{ fontSize: 16, color: colors.white, marginLeft: 5 }}>
            Free
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            color: "black",
            height: 34,
            borderRadius: 12,
            backgroundColor: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: 16, fontWeight: "600", color: "white" }}>
            View
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
