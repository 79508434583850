import React from "react";

const UserTagComponent = ({
  profileImage = "https://api.dicebear.com/7.x/fun-emoji/svg?seed=Buster",
  username = "",
}) => {
  return (
    <div
      className="selector"
      style={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        className="img"
        src={profileImage}
        style={{ height: 50, width: 50, borderRadius: 25 }}
      />
      <div
        style={{
          paddingTop: 2,
          borderRadius: 20,
          paddingBottom: 2,
          paddingRight: 10,
          paddingLeft: 10,
          marginTop: -7,
          marginBottom: 5,
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(30px) brightness(85%)",
        }}
      >
        <div
          style={{
            color: "black",
            fontSize: 12,
            fontWeight: "bold",
            lineHeight: "14px",
          }}
        >
          {username}
        </div>
      </div>
      <div
        style={{
          background: "black",
          height: 10,
          width: 10,
          borderRadius: 5,
        }}
      />
    </div>
  );
};

export default UserTagComponent;
