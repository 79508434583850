import React from "react";
import { colors } from "../CONSTANTS";

const CalendarIcon = ({ color = colors.green }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="calendar" transform="translate(-492 -188)">
        <path
          id="Vector"
          d="M0,4A1,1,0,0,1-1,3V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V3A1,1,0,0,1,0,4Z"
          transform="translate(500 190)"
          fill={color}
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,4A1,1,0,0,1-1,3V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V3A1,1,0,0,1,0,4Z"
          transform="translate(508 190)"
          fill={color}
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M17,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H17a1,1,0,0,1,1,1A1,1,0,0,1,17,1Z"
          transform="translate(495.5 197.09)"
          fill={color}
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M5-1h8c3.813,0,6,2.187,6,6v8.5c0,3.813-2.187,6-6,6H5c-3.813,0-6-2.187-6-6V5C-1,1.187,1.187-1,5-1Zm8,18.5a4.037,4.037,0,0,0,3.08-1.055A4.206,4.206,0,0,0,17,13.5V5a4.206,4.206,0,0,0-.92-2.945A4.037,4.037,0,0,0,13,1H5A4.037,4.037,0,0,0,1.92,2.055,4.206,4.206,0,0,0,1,5v8.5a4.206,4.206,0,0,0,.92,2.945A4.037,4.037,0,0,0,5,17.5Z"
          transform="translate(495 191.5)"
          fill={color}
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          transform="translate(492 188)"
          fill="none"
          opacity="0"
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M.5,1.5H.495a1,1,0,1,1,0-2H.5a1,1,0,0,1,0,2Z"
          transform="translate(507.2 201.2)"
          fill={color}
        />
        <path
          id="Vector-7"
          data-name="Vector"
          d="M.5,1.5H.495a1,1,0,1,1,0-2H.5a1,1,0,0,1,0,2Z"
          transform="translate(507.2 204.2)"
          fill={color}
        />
        <path
          id="Vector-8"
          data-name="Vector"
          d="M.5,1.5H.495a1,1,0,1,1,0-2H.5a1,1,0,0,1,0,2Z"
          transform="translate(503.501 201.2)"
          fill={color}
        />
        <path
          id="Vector-9"
          data-name="Vector"
          d="M.5,1.5H.495a1,1,0,1,1,0-2H.5a1,1,0,0,1,0,2Z"
          transform="translate(503.501 204.2)"
          fill={color}
        />
        <path
          id="Vector-10"
          data-name="Vector"
          d="M.5,1.5H.495a1,1,0,1,1,0-2H.5a1,1,0,0,1,0,2Z"
          transform="translate(499.8 201.2)"
          fill={color}
        />
        <path
          id="Vector-11"
          data-name="Vector"
          d="M.5,1.5H.495a1,1,0,1,1,0-2H.5a1,1,0,0,1,0,2Z"
          transform="translate(499.8 204.2)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CalendarIcon;
