import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../CONSTANTS";

const Footer = () => {
  return (
    <div>
      <div
        style={{
          height: 1,
          backgroundColor: "#F1F1F1",
        }}
      />
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(30px) brightness(115%)",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 76,
        }}
      >
        <Link to="/imprint" style={styles.link}>
          Imprint
        </Link>
        {/* <Link to="/about" style={styles.link}>
          About
        </Link> */}
      </div>
    </div>
  );
};

const styles = {
  link: {
    textDecoration: "none",
    color: colors.black,
    fontSize: 16,
    marginLeft: 20,
  },
};

export default Footer;
