export const colors = {
  red: '#FF2939',
  green: '#29D23F',
  purple: '#8338EC',
  yellow: '#FFBE0B',
  blue: '#0068FF',
  white: '#ffffff',
  black: '#000000',
}

export const text = {
  desktop: {

  },
  tablet: {

  },
  phone: {
    body: 16,
  },

  largestTitle: 42,
  body: 19,
}