import React from "react";
import { colors } from "../CONSTANTS";

const LocationIcon = ({ color = colors.green }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="LocationIcon" transform="translate(-428 -188)">
        <g id="location" transform="translate(428 188)">
          <path
            id="Vector"
            d="M3.12,7.24A4.12,4.12,0,1,1,7.24,3.12,4.125,4.125,0,0,1,3.12,7.24ZM3.12,1A2.12,2.12,0,1,0,5.24,3.12,2.122,2.122,0,0,0,3.12,1Z"
            transform="translate(8.88 7.19)"
            fill={color}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M8.62-1A10,10,0,0,1,14.414.813a9.061,9.061,0,0,1,3.564,5.467,11.3,11.3,0,0,1-1,7.585,19.294,19.294,0,0,1-4.067,5.4,6.193,6.193,0,0,1-8.6,0,19.335,19.335,0,0,1-4.058-5.4A11.327,11.327,0,0,1-.733,6.269,9.062,9.062,0,0,1,2.832.809,10,10,0,0,1,8.62-1Zm0,20a4.166,4.166,0,0,0,2.907-1.175c2.629-2.524,5.541-6.5,4.5-11.1A7.317,7.317,0,0,0,8.62,1a7.315,7.315,0,0,0-7.4,5.712c-1.042,4.6,1.866,8.58,4.489,11.108A4.191,4.191,0,0,0,8.622,19Z"
            transform="translate(3.377 2)"
            fill={color}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(24 24) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default LocationIcon;
