import React from "react";
import { colors } from "../CONSTANTS";
import ClockIcon from "../icons/ClockIcon";

const OpeningHourCard = () => {
  return (
    <div
      className="selector"
      style={{
        display: "inline-flex",
        flexDirection: "column",
        verticalAlign: "top",
      }}
    >
      <div
        style={{
          borderRadius: 15,
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(30px) brightness(60%)",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          height: 52,
          paddingRight: 20,
          paddingLeft: 20,
        }}
      >
        <ClockIcon />
        <div
          style={{
            fontSize: 16,
            color: colors.green,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          Open till 3 am
        </div>
      </div>
    </div>
  );
};

export default OpeningHourCard;
