import React from "react";

const TagComponent = ({ content = "", style, className }) => {
  return (
    <div style={{ ...style }}>
      <div
        className={`selector ${className}`}
        style={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            paddingTop: 7,
            paddingBottom: 7,
            borderRadius: 20,
            paddingRight: 15,
            paddingLeft: 15,
            backgroundColor: "#FFFFFF",
          }}
        >
          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "500",
              lineHeight: "19px",
            }}
          >
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagComponent;
