import React from "react";
import { colors } from "../CONSTANTS";

const BurgerMenuIcon = ({ color = colors.black }) => {
  return (
    <svg
      id="vuesax_linear_menu"
      data-name="vuesax/linear/menu"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="menu">
        <path
          id="Vector"
          d="M0,0H18"
          transform="translate(3 7)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-width="2"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H18"
          transform="translate(3 12)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-width="2"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H18"
          transform="translate(3 17)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-width="2"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};

export default BurgerMenuIcon;
