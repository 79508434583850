import React from "react";
import { colors } from "../CONSTANTS";

const ClockIcon = ({ color = colors.green }) => {
  return (
    <svg
      id="clock"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Vector"
        d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10Z"
        transform="translate(2 2)"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M4.08,7.67.98,5.82A2.215,2.215,0,0,1,0,4.1V0"
        transform="translate(11.63 7.51)"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H24V24H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
};

export default ClockIcon;
