import React from "react";
import { colors } from "../CONSTANTS";

const HeartIcon = ({ color = colors.red }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="HeartIcon" transform="translate(-236 -188)">
        <g id="heart" transform="translate(236 188)">
          <path
            id="Vector"
            d="M10,18.8a2.955,2.955,0,0,1-.948-.145A16.913,16.913,0,0,1,2.887,14.6,12.8,12.8,0,0,1-1,5.59,6.582,6.582,0,0,1,5.56-1,6.458,6.458,0,0,1,10,.746,6.546,6.546,0,0,1,21,5.59,12.8,12.8,0,0,1,17.113,14.6a16.913,16.913,0,0,1-6.165,4.053A2.955,2.955,0,0,1,10,18.8ZM5.56,1A4.58,4.58,0,0,0,1,5.59c0,6.7,6.445,10.4,8.7,11.174l.01,0a1.267,1.267,0,0,0,.574,0l.01,0C12.555,15.993,19,12.286,19,5.59a4.547,4.547,0,0,0-8.2-2.754,1,1,0,0,1-1.606,0A4.493,4.493,0,0,0,5.56,1Z"
            transform="translate(2 3.1)"
            fill={color}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default HeartIcon;
