import React, { useState } from "react";
import conectoryLogo from "./../assets/conectoryLogo.svg";
import { Link, useLocation } from "react-router-dom";
import { colors } from "../CONSTANTS";
import "./NavBar.css";
import BurgerMenuIcon from "../icons/BurgerMenuIcon";
import CloseIcon from "../icons/CloseIcon";

const NavBar = () => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  return (
    <header>
      {visible && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: colors.white,
            position: "fixed",
            zIndex: 100000,
            textAlign: "center",
            overflow: "hidden",
          }}
          className="navbarModal"
        >
          <Link to="">
            <img
              src={conectoryLogo}
              className="img"
              style={{
                height: 36,
                paddingTop: 20,
                paddingBottom: 15,
              }}
            />
          </Link>
          <div
            style={{ position: "absolute", top: 26, right: 20 }}
            onClick={() => setVisible(false)}
          >
            <CloseIcon />
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              textAlign: "center",
            }}
          >
            <Link
              to=""
              style={{
                ...styles.link,
                fontWeight: location.pathname == "/" ? "bold" : "normal",
                paddingTop: 15,
              }}
              onClick={() => setVisible(false)}
            >
              Home
            </Link>
            <Link
              to="/features"
              style={{
                ...styles.link,
                fontWeight:
                  location.pathname == "/features" ? "bold" : "normal",
                paddingTop: 15,
              }}
              onClick={() => setVisible(false)}
            >
              Features
            </Link>
            {/* <Link
              to="/showroom"
              style={{
                ...styles.link,
                fontWeight:
                  location.pathname == "/showroom" ? "bold" : "normal",
                paddingTop: 15,
              }}
              onClick={() => setVisible(false)}
            >
              Showroom
            </Link> */}
            <Link
              to="/for-locations"
              style={{
                ...styles.link,
                fontWeight:
                  location.pathname == "/for-locations" ? "bold" : "normal",
                paddingTop: 15,
                paddingBottom: 15,
              }}
              onClick={() => setVisible(false)}
            >
              For locations
            </Link>
          </div>
        </div>
      )}
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(30px) brightness(115%)",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link to="">
          <img
            src={conectoryLogo}
            className="img titleMargin"
            style={{
              height: 36,
              display: "block",
              borderRadius: 10,
              paddingTop: 20,
              paddingBottom: 20,
              paddingRight: 20,
            }}
          />
        </Link>
        <div
          style={{
            flex: 1,
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link
            to=""
            style={{
              ...styles.link,
              fontWeight: location.pathname == "/" ? "bold" : "normal",
            }}
            className="item"
          >
            Home
          </Link>
          <Link
            to="/features"
            style={{
              ...styles.link,
              fontWeight: location.pathname == "/features" ? "bold" : "normal",
            }}
            className="item"
          >
            Features
          </Link>
          {/* <Link
            to="/showroom"
            style={{
              ...styles.link,
              fontWeight: location.pathname == "/showroom" ? "bold" : "normal",
            }}
            className="item"
          >
            Showroom
          </Link> */}
          <Link
            to="/for-locations"
            style={{
              ...styles.link,
              fontWeight:
                location.pathname == "/for-locations" ? "bold" : "normal",
            }}
            className="item"
          >
            For locations
          </Link>
        </div>
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            marginRight: 20,
          }}
          className="burgerIcon"
          onClick={() => setVisible(true)}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <BurgerMenuIcon />
          </div>
        </div>
      </div>
      <div
        style={{
          height: 1,
          backgroundColor: "#F1F1F1",
        }}
      />
    </header>
  );
};

const styles = {
  link: {
    textDecoration: "none",
    color: colors.black,
    fontSize: 16,
    marginLeft: 20,
  },
};

export default NavBar;
