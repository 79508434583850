import React from "react";

const BurgerPin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="51"
      viewBox="0 0 36 51"
    >
      <g id="Group_4966" data-name="Group 4966" transform="translate(-63 -343)">
        <circle
          id="Ellipse_401"
          data-name="Ellipse 401"
          cx="5"
          cy="5"
          r="5"
          transform="translate(76 384)"
          fill="#131313"
        />
        <rect
          id="Rectangle_5212"
          data-name="Rectangle 5212"
          width="36"
          height="36"
          rx="18"
          transform="translate(63 343)"
          fill="#6dcc79"
        />
        <g
          id="Group_3733"
          data-name="Group 3733"
          transform="translate(-410 -1787)"
        >
          <g
            id="Group_2818"
            data-name="Group 2818"
            transform="translate(60 17.495)"
          >
            <path
              id="Path_3646"
              data-name="Path 3646"
              d="M22.095,38.284H10.743A3.243,3.243,0,0,1,7.5,35.041V34.5H25.338v.541A3.243,3.243,0,0,1,22.095,38.284Z"
              transform="translate(414.581 2101.681)"
              fill="none"
              stroke="#112614"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Path_3647"
              data-name="Path 3647"
              d="M23.878,31.743H7.122A1.622,1.622,0,0,1,5.5,30.122h0A1.622,1.622,0,0,1,7.122,28.5H23.878A1.622,1.622,0,0,1,25.5,30.122h0A1.622,1.622,0,0,1,23.878,31.743Z"
              transform="translate(415.5 2104.438)"
              fill="none"
              stroke="#112614"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Path_3648"
              data-name="Path 3648"
              d="M7.083,18.5l-.411,1.028A1.081,1.081,0,0,0,7.8,21l2.629-.292A2.161,2.161,0,0,1,11.757,21l.426.25a2.162,2.162,0,0,0,1.778.186l1.561-.52a2.16,2.16,0,0,1,1.486.044l1.066.426a2.162,2.162,0,0,0,1.77-.074l.723-.361a2.163,2.163,0,0,1,1.206-.215L24.209,21a1.081,1.081,0,0,0,1.123-1.476L24.921,18.5"
              transform="translate(414.998 2109.032)"
              fill="none"
              stroke="#112614"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="Path_3649"
              data-name="Path 3649"
              d="M25.338,12.446H7.5C7.5,8.864,10.4,6.5,13.986,6.5h4.865c3.582,0,6.486,2.364,6.486,5.946Z"
              transform="translate(414.581 2114.545)"
              fill="none"
              stroke="#112614"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              id="Line_87"
              data-name="Line 87"
              y2="2.162"
              transform="translate(422.622 2130.234)"
              fill="none"
              stroke="#112614"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <line
              id="Line_88"
              data-name="Line 88"
              y2="2.162"
              transform="translate(439.378 2130.234)"
              fill="none"
              stroke="#112614"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </g>
          <g
            id="Rectangle_4313"
            data-name="Rectangle 4313"
            transform="translate(479 2136)"
            fill="none"
            stroke="#112614"
            stroke-width="1"
            opacity="0"
          >
            <rect width="24" height="24" stroke="none" />
            <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BurgerPin;
