import React from "react";
import { colors } from "../CONSTANTS";
import img1 from "../assets/george.png";
import img2 from "../assets/brian.png";

const UsersTagComponent = ({
  profileImage1 = img1,
  profileImage2 = img2,
  text = "",
}) => {
  return (
    <div
      className="selector"
      style={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: 27,
          padding: 2,
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(30px) brightness(85%)",

          display: "inline-flex",
          flexDirection: "row",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          width="79.999"
          height="50"
          viewBox="0 0 79.999 50"
        >
          <defs>
            <pattern
              id="pattern"
              preserveAspectRatio="xMidYMid slice"
              width="100%"
              height="100%"
              viewBox="0 0 878 878"
            >
              <image width="878" height="878" href={profileImage1} />
            </pattern>
            <pattern
              id="pattern-2"
              preserveAspectRatio="none"
              width="100%"
              height="100%"
              viewBox="0 0 888 888"
            >
              <image width="888" height="888" href={profileImage2} />
            </pattern>
          </defs>
          <g
            id="Group_4994"
            data-name="Group 4994"
            transform="translate(10396 9753.536)"
          >
            <path
              id="Intersection_109"
              data-name="Intersection 109"
              d="M23.06,49.926a24.862,24.862,0,0,1-7.427-1.74l-.03-.012-.024-.01q-.5-.2-1-.432c.554-.478,1.1-.988,1.634-1.518a29.894,29.894,0,0,0,6.427-9.535A29.806,29.806,0,0,0,25,25a29.811,29.811,0,0,0-2.36-11.678,29.9,29.9,0,0,0-6.427-9.535c-.525-.525-1.075-1.035-1.634-1.517q.458-.21.923-.4l.026-.011.028-.011A24.916,24.916,0,0,1,24.837,0H25a24.94,24.94,0,0,1,8.947,1.649l.039.015.023.009q.363.14.722.292.272.115.54.236l.036.016.074.033a25.036,25.036,0,0,1,6.354,4.177l.042.038.029.026q.445.4.872.832.48.48.932.982l.031.035.032.035a25.056,25.056,0,0,1,3.689,5.432l.041.082.008.015q.332.671.625,1.364.334.79.612,1.6l.01.03.008.024a24.88,24.88,0,0,1,1.221,5.68v.014l0,.046Q50,23.82,50,25q0,1.258-.122,2.5l-.005.052v.012a24.86,24.86,0,0,1-1.128,5.291q-.315.951-.708,1.881-.352.833-.761,1.634l-.007.014-.041.08a25.057,25.057,0,0,1-3.555,5.168l-.028.031-.034.038q-.451.5-.931.981-.427.427-.871.831l-.034.03-.034.031a25.03,25.03,0,0,1-6.361,4.181l-.065.03-.043.019q-.267.12-.538.235-.361.153-.725.294h0l-.064.025-.037.014-.026.01a24.845,24.845,0,0,1-6.463,1.507l-.055.005h-.005Q26.186,50,25,50,24.024,50,23.06,49.926ZM10,45h0q-.47-.353-.923-.728a25.3,25.3,0,0,1-5.713-6.741l-.007-.011-.016-.028A24.885,24.885,0,0,1,0,25.325v0c0-.023,0-.048,0-.07v-.028c0-.017,0-.035,0-.052s0-.031,0-.046,0-.026,0-.04c0-.029,0-.058,0-.086,0,0,0-.006,0-.009,0-.025,0-.05,0-.075s0-.037,0-.055,0-.019,0-.028,0-.047,0-.071v-.006A24.878,24.878,0,0,1,3.423,12.365l.011-.018.021-.035A25.266,25.266,0,0,1,9.644,5.271l.006,0,.062-.048.009-.007.06-.046.009-.007L10,5a25.223,25.223,0,0,1,7.277,8.644A24.733,24.733,0,0,1,20,25a24.74,24.74,0,0,1-2.722,11.359A25.24,25.24,0,0,1,10,45Z"
              transform="translate(-10366 -9753.536)"
              fill="url(#pattern)"
            />
            <rect
              id="Image_251"
              data-name="Image 251"
              width="50"
              height="50"
              rx="25"
              transform="translate(-10396 -9753.536)"
              fill="url(#pattern-2)"
            />
          </g>
        </svg>
      </div>
      <div
        style={{
          paddingTop: 2,
          borderRadius: 20,
          paddingBottom: 2,
          paddingRight: 10,
          paddingLeft: 10,
          marginTop: -7,
          marginBottom: 5,
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(30px) brightness(85%)",
        }}
      >
        <div
          style={{
            color: colors.yellow,
            fontSize: 12,
            fontWeight: "bold",
            lineHeight: "14px",
          }}
        >
          {text}
        </div>
      </div>
      <div
        style={{
          background: colors.yellow,
          height: 10,
          width: 10,
          borderRadius: 5,
        }}
      />
    </div>
  );
};

export default UsersTagComponent;
