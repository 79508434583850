import React from "react";
import { colors } from "../CONSTANTS";
import georgeProfileImage from "../assets/george.png";

const NotificationComponent = () => {
  return (
    <div
      className="selector"
      style={{
        display: "inline-flex",
        flexDirection: "column",
        verticalAlign: "top",
        width: 335,
      }}
    >
      <div
        style={{
          borderRadius: 23,
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(30px) brightness(60%)",
          flexDirection: "row",
          display: "flex",
          paddingTop: 14,
          paddingBottom: 14,
          paddingLeft: 14,
          paddingRight: 23,
        }}
      >
        <img
          src={georgeProfileImage}
          className="img"
          style={{
            width: 38,
            height: 38,
            display: "block",
            borderRadius: 10,
          }}
        />
        <div
          style={{
            marginLeft: 10,
            display: "flex",
            alignItems: "center",
            height: 38,
            flex: 1,
          }}
        >
          <div>
            <div
              style={{
                fontSize: 16,
                color: colors.white,
                fontWeight: "500",
              }}
            >
              George
            </div>
            <div
              style={{
                fontSize: 14,
                color: colors.white,
              }}
            >
              Posted an activity
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: 13,
            color: colors.white,
          }}
        >
          now
        </div>
      </div>
    </div>
  );
};

export default NotificationComponent;
