import React from "react";
import { colors } from "../CONSTANTS";

const StarIcon = ({ color = colors.yellow }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_linear_star"
        data-name="vuesax/linear/star"
        transform="translate(-428 -188)"
      >
        <g id="star" transform="translate(428 188)">
          <g id="Group" transform="translate(5.021 3.062)">
            <path
              id="Vector"
              d="M7.982-1A2.578,2.578,0,0,1,10.263.7L11.674,3.52l0,.009a.838.838,0,0,0,.408.294l2.536.418a2.65,2.65,0,0,1,2.258,1.65,2.663,2.663,0,0,1-.87,2.671l-1.993,1.993a.8.8,0,0,0-.143.515l.57,2.462a2.964,2.964,0,0,1-.358,2.63,1.96,1.96,0,0,1-1.574.729,3.682,3.682,0,0,1-1.855-.595L8.269,14.877l-.007,0a.758.758,0,0,0-.545,0l-.007,0L5.32,16.3a3.7,3.7,0,0,1-1.853.589,1.962,1.962,0,0,1-1.576-.729,2.959,2.959,0,0,1-.356-2.626l.57-2.46,0-.007a.814.814,0,0,0-.152-.518L-.028,8.565A2.653,2.653,0,0,1-.9,5.908,2.659,2.659,0,0,1,1.35,4.242h.007l2.55-.42A.813.813,0,0,0,4.3,3.529l0-.009L5.714.7A2.642,2.642,0,0,1,7.982-1Zm1.9,5.411L8.475,1.595c-.232-.461-.447-.586-.493-.595-.044.009-.253.135-.475.587l0,.007L6.1,4.412A2.717,2.717,0,0,1,4.23,5.794l-2.546.419c-.511.089-.671.262-.683.3s.019.27.385.637l1.99,1.99.01.011a2.734,2.734,0,0,1,.666,2.375l-.569,2.457a1.731,1.731,0,0,0-.039.9h.023a1.77,1.77,0,0,0,.834-.31l2.386-1.418a2.69,2.69,0,0,1,2.605,0l2.388,1.419a1.769,1.769,0,0,0,.832.313h.021a1.726,1.726,0,0,0-.038-.907l-.57-2.46A2.713,2.713,0,0,1,12.6,9.14l1.99-1.99c.341-.343.4-.593.385-.644s-.208-.214-.681-.292l-2.549-.42-.02,0A2.763,2.763,0,0,1,9.883,4.411Z"
              transform="translate(1 1)"
              fill={color}
            />
          </g>
          <path
            id="Vector-2"
            data-name="Vector"
            d="M6,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H6A1,1,0,0,1,7,0,1,1,0,0,1,6,1Z"
            transform="translate(2 5)"
            fill={color}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M3,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H3A1,1,0,0,1,4,0,1,1,0,0,1,3,1Z"
            transform="translate(2 19)"
            fill={color}
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M1,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H1A1,1,0,0,1,2,0,1,1,0,0,1,1,1Z"
            transform="translate(2 12)"
            fill={color}
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default StarIcon;
