import React from "react";
import { colors } from "../CONSTANTS";

const LightningIcon = ({ color = colors.blue }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="FlashOnIcon" transform="translate(-428 -316)">
        <g id="flash" transform="translate(428 316)">
          <path
            id="Vector"
            d="M5.27,21c-.3,0-1.811-.121-1.811-2.521v-6.2H1.368A2.183,2.183,0,0,1-.846,11.135a2.189,2.189,0,0,1,.589-2.43l0-.005L7.318.1A2.7,2.7,0,0,1,9.277-1c.3,0,1.811.122,1.811,2.521v6.2h2.09A2.2,2.2,0,0,1,15.4,8.877,2.2,2.2,0,0,1,14.8,11.3L7.229,19.9A2.705,2.705,0,0,1,5.27,21Zm-4.2-10.743a1.745,1.745,0,0,0,.294.022h3.09a1,1,0,0,1,1,1v7.2a2.758,2.758,0,0,0,.02.351,2.741,2.741,0,0,0,.25-.252l7.57-8.6a1.758,1.758,0,0,0,.179-.236,1.75,1.75,0,0,0-.3-.023h-3.09a1,1,0,0,1-1-1v-7.2a2.758,2.758,0,0,0-.02-.351,2.741,2.741,0,0,0-.25.252l-7.568,8.6A1.749,1.749,0,0,0,1.074,10.259Z"
            transform="translate(4.722 1.999)"
            fill={color}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default LightningIcon;
