import React from "react";

const IconComponent = ({ icon, style, className }) => {
  return (
    <div style={{ ...style }} className={className}>
      <div
        style={{
          display: "inline-flex",
          padding: 10,
          borderRadius: 25,
          backgroundColor: "rgba(255, 255, 255, 0)",
          backdropFilter: "blur(30px) brightness(115%)",
        }}
      >
        {icon}
      </div>
    </div>
  );
};

export default IconComponent;
