import React, { useState } from "react";
import "./Imprint.css";
import BurgerPin from "../components/PinComponents/BurgerPin";
import UserTagComponent from "../components/UserTagComponent";
import UsersTagComponent from "../components/UsersTagComponent";
import TagComponent from "../components/TagComponent";
import NotificationComponent from "../components/NotificationComponent";
import IconComponent from "../components/IconComponent";
import ClubIcon from "../icons/ClubIcon";
import StarIcon from "../icons/StarIcon";
import LocationIcon from "../icons/LocationIcon";
import LightningIcon from "../icons/LightningIcon";
import HeartIcon from "../icons/HeartIcon";
import EventCard from "../components/EventCard";
import RoomCard from "../components/RoomCard";
import lisa from "../assets/lisa.png";
import marc from "../assets/marc.png";
import munichmap from "../assets/munichmap.png";
import ChatBubble from "../components/ChatBubble";
import { colors } from "../CONSTANTS";
import OpeningHourCard from "../components/OpeningHourCard";
import conectoryLogo from "./../assets/conectoryLogo.svg";
import "./Home.css";
import "./ItemPositions.css";
import img1 from "../assets/i_am_here_screen.png";
import img2 from "../assets/map_screen.png";
import img3 from "../assets/empty_map_screen_i_am_here.png";
import img4 from "../assets/Group 5007.png";
import img5 from "../assets/Group 5008.png";
import img6 from "../assets/Group 5009.png";

const HomePage = () => {
  return (
    <div>
      <div style={{ maxHeight: 800 }} class="extraSidePadding">
        <div style={{ textAlign: "center" }}>
          <img
            src={conectoryLogo}
            className="img conectoryTitle"
            style={{
              margin: "0 auto",
              display: "inline-block",
            }}
          />
        </div>
        <div style={{ textAlign: "center", marginBottom: 120 }}>
          <TagComponent content="Your portal to the real life" />
        </div>
        <div
          style={{
            maxHeight: 418,
            maxWidth: 547,
            marginTop: 120,
            margin: "0 auto",
            position: "relative",
          }}
        >
          <img
            className="img mapImage"
            src={munichmap}
            style={{
              // height: 418,
              // width: 547,
              borderRadius: 20,
            }}
          />
          <div className="item1">
            <BurgerPin />
          </div>
          <div className="item2">
            <BurgerPin />
          </div>
          <div className="item3">
            <UserTagComponent username="Pete" />
          </div>
          <div className="item4">
            <UsersTagComponent text="8 friends" />
          </div>
          <div className="item5">
            <NotificationComponent />
          </div>

          <div className="item6">
            <EventCard />
          </div>
          <div className="item7">
            <RoomCard />
          </div>

          <IconComponent icon={<LocationIcon />} className="item8" />
          <IconComponent icon={<ClubIcon />} className="item9" />
          <IconComponent icon={<StarIcon />} className="item10" />
          <IconComponent icon={<LightningIcon />} className="item11" />
          <IconComponent icon={<HeartIcon />} className="item12" />

          <div
            style={{
              zIndex: 2,
            }}
            className="item13"
          >
            <div
              style={{
                position: "absolute",
                right: -30,
                top: -50,
                zIndex: -1,
              }}
            >
              <img
                className="img"
                src={lisa}
                style={{ height: 60, width: 60, borderRadius: 30 }}
              />
            </div>
            <ChatBubble
              text="Bro let's go out this weekend"
              received={false}
              color={colors.blue}
            />
          </div>
          <div className="item14">
            <div
              style={{
                position: "absolute",
                left: -30,
                top: -50,
                zIndex: -1,
              }}
            >
              <img
                className="img"
                src={marc}
                style={{ height: 60, width: 60, borderRadius: 30 }}
              />
            </div>
            <ChatBubble text="Will there be a crazy event?" />
          </div>

          <div className="item15">
            <OpeningHourCard />
          </div>

          <TagComponent content="#Events" className="item16" />
          <TagComponent content="#Communities" className="item17" />
          <TagComponent content="#Clubs" className="item18" />
          <TagComponent content="#Map" className="item19" />
          <TagComponent content="#Bars" className="item20" />
          <TagComponent content="#Restaurants" className="item21" />
          <TagComponent content="#Friends" className="item22" />
        </div>
      </div>

      <div class="extraSidePadding responsiveTopMargin">
        <div className="fontLargestTitle" style={{ textAlign: "center" }}>
          Conectory Maps
        </div>
        <div
          className="fontBody"
          style={{
            backgroundColor: colors.white,
            padding: 20,
            wordWrap: "break-word",
            borderRadius: 20,
            marginTop: 20,
          }}
        >
          Chat directly in Conectory to stay in touch with your friends. You
          have unlimited possibilities how to use the chat. Share an event,
          write messages or share pictures from the last party.
        </div>
        <div className="mapsMockups">
          <img src={img1} className="mockupWidth" />
          <img src={img2} className="mockupWidth" />
          <img src={img3} className="mockupWidth" />
        </div>
      </div>

      <div class="sidePadding">
        <div
          class="card"
          id="card-margin-and-padding-1"
        >
          <div>
            <div class="grid-container-home">
              <div class="grid-item-home grid-item-image-left">
                <img
                  src={img4}
                  className="img grid-image"
                  style={{
                    margin: "0 auto",
                    display: "inline-block",
                    // height: 600,
                  }}
                />
              </div>
              <div class="grid-item-home grid-item-text-right">
                <div
                  class="fontLargestTitle"
                  style={{
                    textAlign: "start",
                  }}
                >
                  Want to chat with your friends without big corporations
                  reading along?
                </div>
                <div
                  class="fontBody"
                  style={{
                    textAlign: "start",
                    marginTop: 20,
                  }}
                >
                  Chat directly in Conectory to stay in touch with your friends.
                  Share an event, write messages or share pictures from the last
                  party - all absolutely private; only your friend and you read
                  the messages.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidePadding">
        <div
          className="card"
          id="card-margin-and-padding-2"
        >
          <div>
            <div class="grid-container-home">
              <div class="grid-item-home grid-item-image-left">
                <img
                  src={img5}
                  className="img grid-image"
                  style={{
                    margin: "0 auto",
                    display: "inline-block",
                    // height: 600,
                  }}
                />
              </div>
              <div class="grid-item-home grid-item-text-right">
                <div
                  class="fontLargestTitle"
                  style={{
                    textAlign: "start",
                  }}
                >
                  From now on you always know where you want to go party today
                </div>
                <div
                  class="fontBody"
                  style={{
                    textAlign: "start",
                    marginTop: 20,
                  }}
                >
                  With Conectory Locations you can discover clubs, bars and much
                  more. Follow your favorite clubs and get notified about new
                  events. Best of all, all with your friends
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidePadding">
        <div
          class="card"
          id="card-margin-and-padding-3"
        >
          <div>
            <div class="grid-container-home">
              <div class="grid-item-home grid-item-image-left">
                <img
                  src={img6}
                  className="img grid-image"
                  style={{
                    margin: "0 auto",
                    display: "inline-block",
                    // height: 600,
                  }}
                />
              </div>
              <div class="grid-item-home grid-item-text-right">
                <div
                  class="fontLargestTitle"
                  style={{
                    textAlign: "start",
                  }}
                >
                  Black or white? You can decide
                </div>
                <div
                  class="fontBody"
                  style={{
                    textAlign: "start",
                    marginTop: 20,
                  }}
                >
                  Experience Conectory all in black or white. Choose or match
                  the colors to your device. Decide which theme is right for you
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
