import React from "react";
import "./Imprint.css";
import "./Home.css";
import "./Features.css";
import "./ItemPositions.css";
import iamhere from "../assets/iamhere.png";
import locations from "../assets/locations.png";
import events from "../assets/events.png";
import chat from "../assets/chat.png";

const FeaturesPage = () => {
  return (
    <div class="featuresSidePadding">
      <div class="grid-container">
        <div class="grid-item" id="one">
          <div class="card">
            <div style={{ flexDirection: "column", display: "flex" }}>
              <img src={iamhere} class="img featuresImage" />
              <div class="fontLargeTitle" style={{ marginTop: 20 }}>
                I am here
              </div>
              <div class="fontBody" style={{ marginTop: 10 }}>
                Share with your friends where you are and what you are doing and
                let them join.
              </div>
            </div>
          </div>
        </div>
        <div class="grid-item" id="two">
          <div class="card">
            <div style={{ flexDirection: "column", display: "flex" }}>
              <img src={locations} class="img featuresImage" />
              <div class="fontLargeTitle" style={{ marginTop: 20 }}>
                Locations
              </div>
              <div class="fontBody" style={{ marginTop: 10 }}>
                Clubs, bars and more at your fingertips. Explore the nightlife
                near you
              </div>
            </div>
          </div>
        </div>
        <div class="grid-item" id="three">
          <div class="card">
            <div style={{ flexDirection: "column", display: "flex" }}>
              <img src={events} class="img featuresImage" />
              <div class="fontLargeTitle" style={{ marginTop: 20 }}>
                Events
              </div>
              <div class="fontBody" style={{ marginTop: 10 }}>
                Clubs, bars and more at your fingertips. Explore the nightlife
                near you
              </div>
            </div>
          </div>
        </div>
        <div class="grid-item" id="four">
          <div class="card">
            <div style={{ flexDirection: "column", display: "flex" }}>
              <img src={chat} class="img featuresImage" />
              <div class="fontLargeTitle" style={{ marginTop: 20 }}>
                Chat
              </div>
              <div class="fontBody" style={{ marginTop: 10 }}>
                Clubs, bars and more at your fingertips. Explore the nightlife
                near you
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesPage;
